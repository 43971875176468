<template>
  <interna>
    <breadcrumb
      :itens="[
        { legenda: 'Dashboard', route: { name: 'dashboard' } },
        { legenda: 'Download' },
      ]"
    />
    <section id="inscricao">
      <div class="container">
        <div class="row my-2 my-md-5">
          <div class="col-1-1">
            <h4 class="text-center">
              Baixe o seu selo clicando no botão abaixo.
            </h4>
          </div>
        </div>
      </div>

      <div class="container" v-show="selo">
        <div class="row my-5">
          <div
            class="col-1-1 col-md-2-8 offset-md-1-8 padding-x1 padding-md-x0"
          >
            <h2 :style="{ color: selo.Cor }">{{ categoria.Nome }}</h2>
            <img
              :src="require('@/assets/images/selos/' + selo.Imagem)"
              class="selo"
            />
          </div>
          <div class="col-1-1 col-md-4-8 padding-t3 text-center">

            <div v-show="categoria.Nome == 'Guias de Turismo'" class="margin-t3 padding-x2">

                <p>
                    Parabéns, você atendeu aos critérios!
                </p>
                <p>
                    Dirija-se à Secretaria de Turismo, ao Departamento de Planejamento, para a retirada do Botton de identificação, com validade de 1 ano a partir deste dia.
                </p>
            </div>

            <div v-show="categoria.Nome != 'Guias de Turismo'">
                <p>
                    Parabéns, você atendeu aos critérios para esta Categoria!
                </p>
                <p>
                    Dirija-se à Secretaria de Turismo, ao Departamento de Planejamento, para a retirada do Selo de Reconhecimento que será utilizado no seu estabelecimento, com validade de 1 ano à partir deste dia.
                </p>
                <button class="btn btn-lg btn-primary margin-y1">
                <i class="bi bi-file-earmark-pdf"></i>Baixar mídia kit
                </button>

                <p>
                    Após clicar no botão, seu navegador irá começar a baixar um arquivo pdf com o seu mídia kit para usar nas redes sociais.
                </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  </interna>
</template>
<script>
export default {
  metaInfo: {
    title: "Download",
  },
  data: function () {
    return {
      selo: null,
      categoria: null,
    };
  },

  mounted: function () {
    this.$sdk.get("/selos", { link: this.$route.params.selo }, (res) => {
      this.selo = res.data[0];
    });
    this.$sdk.get(
      "/categorias",
      { link: this.$route.params.categoria },
      (res) => {
        this.categoria = res.data[0];
      }
    );
  },
};
</script>

<style lang="scss">
section#inscricao {
  img.selo {
    max-width: 100%;
  }

  p{
    margin: 7px 0;
  }

  h2 {
    font-size: 25px;
    text-align: center;
    padding: 15px 0;
  }
}
</style>